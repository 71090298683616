@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
.rich-text-wrapper {
	a[href^="tel"]::before {
		content: "\f095";
		font: var(--fa-font-solid);
		padding-right: 0.3em;
		display: none;
		font-size: 16px;

		svg {
			height: 14px;
			width: 14px;
		}
	}
	p + p {
		margin-top: 25px;
	}

	ul {
		margin-left: 22px;
		margin-bottom: 24px;
		line-height: 1.75;
		li {
			margin-bottom: 20px;
			overflow: hidden;
			padding-left: 16px;
			position: relative;
			p {
				margin-bottom: 0;
			}
			:first-child {
				margin-top: 0;
			}
			&:before {
				color: $brand-primary-color;
				content: "\2022";
				top: -8px;
				left: 0;
				font-size: 24px;
				position: absolute;
			}
		}
	}
	.is-condition .is-rich-text {
		:where(img, svg) {
			display: block;
			margin: 20px auto;
		}

		p + img:only-of-type,
		p + svg:only-of-type {
			padding-top: 15px;
		}

		img:only-of-type,
		svg:only-of-type {
			margin: 0 0 0 5px;
		}

		svg[data-icon="f08e"] {
			transition: transform 0.4s ease;
			transform: scale(0.6);
			&:hover {
				transform: scale(0.8);
			}
		}

		// Clearfix to contain floated elements
		&::after {
			content: "";
			display: table;
			clear: both;
		}
		svg.fa-f095 {
			float: none;
			margin: 0 5px;
			display: inline-block;
		}

		@include ch-break-down("sm") {
			font-size: 18px;
		}
	}
	.is-rich-text {
		svg.fa-f095,
		svg.fa-phone {
			margin-right: 5px;
		}
	}
	svg.fa-f08e {
		margin-left: 6px;
		transition: all 0.4s;
		transform: scale(0.6);
		&:hover {
			-webkit-transform: scale(0.8);
			transform: scale(0.8);
		}
	}

	a {
		color: #6629b7;
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		&[href*="tel"] {
			display: inline-block;
			vertical-align: middle;
		}
	}

	p a.contact-phone {
		display: flex;
	}

	.resources-container svg {
		margin: 0 0 8px 20px !important;
	}

	/*
* This selector targets links that are NOT:
* - pointing to childrens.com
* - pointing to childrens.surveymonkey.com
* - relative paths (starting with /)
* - telephone links
* - mailto links
* - page anchors (#)
* - the last child element
* Used to add custom styling for external links via ::after pseudo-element
*/
	a:not([href*="childrens.com"]):not(
			[href*="childrens.surveymonkey.com"]
		):not([href^="/"]):not(h3 a):not([href*="tel"]):not(
			[href*="mailto"]
		):not([href*="#"])::after {
		content: "\f08e";
		font: var(--fa-font-solid);
		padding-left: 0.4em;
		display: none;
		transform: scale(0.6);
		transition: transform 0.4s ease;
	}

	a:not([href*="childrens.com"]):not([href^="/"]):not(h3 a):not(
			[href*="tel"]
		):not([href*="mailto"]):not([href*="#"]):hover::after {
		transform: scale(0.8);
	}
}
